import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import './haydarkadioglu.css';

const CV = () => {
  return (
    <>
      <Helmet>
        <title>Haydar Kadıoğlu</title>
        <meta charSet="utf-8" />
      </Helmet>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
        className="cv-container"
      >
        <div className="background-animation"></div>
        <div className="cv-content">
          <div className="cv-header">
            <div className="profile-section">
              <img
                src={require('../../assets/images/haydar.jpg')}
                alt="Abdullah H. Kadıoğlu"
                className="profile-image"
              />
              <div className="social-links">
                <a href="https://github.com/haydarkadioglu" target="_blank" rel="noopener noreferrer">
                  <img src={require('../../assets/icons/github.ico')} alt="GitHub" className="social-icon" />
                  <span>haydarkadioglu</span>
                </a>
                <a href="https://www.linkedin.com/in/haydarkadioglu/" target="_blank" rel="noopener noreferrer">
                  <img src={require('../../assets/icons/linkedin.ico')} alt="LinkedIn" className="social-icon" />
                  <span>haydarkadioglu</span>
                </a>
                <a href="https://www.instagram.com/haydarkadioglu_/" target="_blank" rel="noopener noreferrer">
                  <img src={require('../../assets/icons/instagram.ico')} alt="Instagram" className="social-icon" />
                  <span>haydarkadioglu_</span>
                </a>
              </div>
            </div>
            <div className="contact-info">
              <h1>Haydar Kadıoğlu</h1>
              <p>Email: a.haydar.kadioglu@gmail.com</p>
            </div>
          </div>

          <div className="cv-grid">
            <section className="cv-section">
              <h2>Education</h2>
              <div className="cv-item">
                <h3>Selçuk University</h3>
                <p>Computer Engineering</p>
                <p>2021-2025</p>
              </div>
            </section>

            <section className="cv-section">
              <h2>Experience</h2>
              <div className="cv-item">
                <h3>Inserpo Bilişim</h3>
                <p>Intern Engineer</p>
                <p>July 2024 - August 2024</p>
              </div>
            </section>

            <section className="cv-section">
              <h2>Skills</h2>
              <div className="skills-container">
                <ul className="skills-list">
                  <li>Python
                    <ul>
                      <li>Data Analysis and Manipulation</li>
                      <li>Image Processing</li>
                      <li>Deep Learning</li>
                      <li>Machine Learning</li>
                      <li>TensorFlow, PyTorch</li>
                    </ul>
                  </li>
                  <li>Dart
                    <ul>
                      <li>Flutter</li>
                    </ul>
                  </li>
                  <li>C#
                    <ul>
                      <li>.Net Core</li>
                    </ul>
                  </li>
                  <li>C/C++</li>
                  <li>Java</li>
                  <li>JavaScript
                    <ul>
                      <li>React.js</li>
                      <li>Node.js</li>
                    </ul>
                  </li>
                  <li>Networking
                    <ul>
                      <li>Catalyst Switches</li>
                      <li>Networking</li>
                      <li>Wireless Networking</li>
                      <li>Virtual Private Network</li>
                      <li>Network Devices Configuration</li>
                    </ul>
                  </li>
                </ul>
              </div>
            </section>

            <section className="cv-section">
              <h2>Certificates</h2>
              <div className="certificates-container">
                <div className="cert-group">
                  <h3>Turkcell Geleceği Yazanlar</h3>
                  <ul>
                    <li>Python 401</li>
                    <li>OpenCV 501</li>
                    <li>Deep Learning 501</li>
                    <li>Basic Network</li>
                  </ul>
                </div>
                <div className="cert-group">
                  <h3>LinkedIn</h3>
                  <ul>
                    <li>Cisco CCNA (200-301) Cert Prep: 1 Network Fundamentals and Access</li>
                    <li>Generative AI</li>
                  </ul>
                </div>
                <div className="cert-group">
                  <h3>Microsoft</h3>
                  <ul>
                    <li>Azure Fundamentals</li>
                  </ul>
                </div>
              </div>
            </section>

          </div>
          
          <section className="cv-section">
            <h2>Projects</h2>
            <div className="projects-container single-column">
              {[
                {
                  title: 'Restoration Application (Non-GitHub)',
                  description:
                    'Developed an image colorization model based on Deep Learning using CNN and GAN architectures. Created a user-friendly interface with Node.js for end-user accessibility.',
                  link: null, // Eğer link yoksa null bırakabilirsiniz
                },
                {
                  title: 'AI-Based Noise Filtering (Non-GitHub, Ongoing)',
                  description:
                    'Developing an AI-powered application to remove noise from audio during online meetings and recordings.',
                  link: null,
                },
                {
                  title: 'Twitter Profile Profile Analysis',
                  description:
                    'This project uses NLP and machine learning for both sentence analysis and comprehensive Twitter profile sentiment analysis, utilizing data from Kaggle and Twitter, processed with models like Logistic Regression and XGBoost to uncover emotional insights and user behavior patterns.',
                  link: 'https://github.com/haydarkadioglu/twitter-profile-analysis',
                },
                {
                  title: 'BTK Datathon 2024 Competition Solution',
                  description:
                    'Participated in the 2024 BTK Datathon competition and achieved a 7.9 RMSE score on the Kaggle platform.',
                  link: 'https://github.com/haydarkadioglu/btk-datathon24',
                },
                {
                  title: 'Aircraft Model Prediction via Image Classification',
                  description:
                    'Developed a machine learning model to predict aircraft models through image classification techniques.',
                  link: 'https://github.com/haydarkadioglu/image_classification_predict_plane',
                },
                {
                  title: 'Sentence Analysis',
                  description:
                    'Designed a tool to evaluate sentences, assigning a sentiment score between -1 and 1 to indicate negative, neutral, or positive sentiment.',
                  link: 'https://github.com/haydarkadioglu/sentence_analysis',
                },
                {
                  title: 'Detection Objects Project',
                  description:
                    'Created a Python application capable of detecting faces, eyes, bodies, colors, and objects.',
                  link: "https://github.com/haydarkadioglu/detection-objects",
                },
                {
                  title: 'Simple Image Processing Application',
                  description:
                    'Implemented an application utilizing image processing techniques to perform basic operations.',
                  link: "https://github.com/haydarkadioglu/simple-image-processing-application",
                },
                {
                  title: 'Statistical Concepts Calculation',
                  description:
                    'Developed a tool to compute statistical measures such as mode, mean, median, standard deviation, variance, skewness, and kurtosis.',
                  link: 'https://github.com/haydarkadioglu/calculating-statistics-concepts',
                },
                {
                  title: 'Memory Game with C#',
                  description: 'Designed and coded a memory-enhancing game using C#.',
                  link: "https://github.com/haydarkadioglu/memory_game_with_csharp",
                },
              ].map((project, index) => (
                <a
                  href={project.link || '#'}
                  target={project.link ? '_blank' : '_self'}
                  rel="noopener noreferrer"
                  className="project-link"
                  key={index}
                >
                  <div className="project-item">
                    <h3>{project.title}</h3>
                    <p>{project.description}</p>
                  </div>
                </a>
              ))}
            </div>
          </section>



        </div>
      </motion.div>
    </>
  );
};

export default CV;
